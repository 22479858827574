section.leeg-81 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;



    .block {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @media @medium, @large {
            height: @leegheight;
        }
        @media @medium {
            height: (@leegheight*0.8);
        }
        @media @small {
            height: (@leegheight*0.6);
        }
        @media @phone, @xsmall {
            height: (@leegheight*0.4);
        }
    }
}
