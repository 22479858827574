section.content-14 {
    .col1, .block-title {
        .make-sm-column(10);
        .col-centered;
        @media @phone, @xsmall {

        }

    }
}
