section.projecten-256 {

    &.project-filters {
        text-align: left;
        padding-top: 10px;

        .block {
            padding-left: 15px;
            padding-right: 15px;
        }

        h6 {
            color: fade(@grey, 70%);
            .font-size(20);
            margin: 0 0 15px 0;
        }

        a {
            display: inline-block;
            color: @color1;
            margin-right: 15px;
            .font-size(16);
            text-decoration: none;
            text-transform: uppercase;

            &.active, &:hover {
                color: @grey;
            }
            &.active {
                border-bottom: 1px solid @color1;
            }
        }
    }

    &.project-list {

    }

    .block {


    }

    .project {
        .make-xs-column(12);
        .make-sm-column(3);
        display: none;

        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
    }

    .searchbox {
        position: relative;
        .make-xs-column(6);
        .make-sm-column(3);
        .make-md-column(2);
        min-width: 220px;
        .col-centered;
        margin-top: 15px;
        -webkit-appearance: none;
        border: 1px solid fade(@grey, 70%);
        .lh-border-radius(0);
        padding: 3px 40px 3px 10px;
        background: url('../images/search_bg.png') @white no-repeat right center;


        &::-webkit-input-placeholder {
            text-transform: uppercase;
        }

        &:-moz-placeholder { /* Firefox 18- */
            text-transform: uppercase;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            text-transform: uppercase;
        }

        &:-ms-input-placeholder {
            text-transform: uppercase;
        }
    }
}
