section.content-15 {

    .block {
        padding: 0 15px;
    }

    .col1, .block-title {
        // set width of column
        .make-sm-column(10);
        .make-md-column(8);
        .col-centered;
    }

    .toggle_extra_content {

        text-align: center;
        h4 {
            text-align: center;
            position: relative;
            cursor: pointer;
            .font-size(16);
            text-transform: uppercase;
            display: inline-block;
            margin: 0 auto !important;

            &:hover {
                color: red;
                border-color: red;
            }

            &.up {
                color: #ccc;
            }

        }
    }

    .extra_content {
        margin-top: 35px;
        overflow: hidden;

        &.collapsed {
            display: none;
        }
    }
}






