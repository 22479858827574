section.contact-132 {
    //padding-top: 0;
    //padding-bottom: 0;


    .col1 {
        .make-sm-column(6);
        .make-md-column(5);
        .make-md-column-offset(1);
        .make-lg-column(3);
        .make-lg-column-offset(1);
    }

    .col2 {
        .make-sm-column(6);
        .make-md-column(6);
        .make-lg-column(7);

    }



}
