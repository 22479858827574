a.project-banner {
    display: block;
    height: 200px;
    background-size: cover;
    background-position: center;
    color: @white;
    text-decoration: none;
    overflow: hidden;

    @media @medium, @large {
        height: 200px;
    }

    @media @small {
        height: 180px;
    }

    @media @phone, @xsmall {
        height: 140px;
    }

    div {
        position: relative;
        background: fade(@white, 65%);
        .lh-transition(background 0.2s ease);

        &:after {
            position: absolute;
            bottom: -9px;
            left: 50%;
            margin-left: -8px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9px 8px 0 8px;
            border-color: fade(@white, 65%) transparent transparent transparent;

        }
    }
    h3 {
        .font-size(18) !important;
        text-align: center !important;
        color: @textcolor !important;
        margin: 0 !important;
        padding: 20px 0 !important;
        border: none !important;

    }

    h5 {
        .font-size(18);
        color: @textcolor !important;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.3;
        padding: 0;
        margin: 0 !important;
        overflow: hidden;
        border: none;
        max-height: 0px;
        .lh-transition(max-height 0.2s ease, padding 0.2s ease);
    }

    &:hover {
        div {
            background: fade(@white, 85%);
            &:after {
                border-color: fade(@white, 85%) transparent transparent transparent;
            }
        }
        h5 {
            max-height: 100px;
            padding: 8px 0 25px;
        }

    }
}
