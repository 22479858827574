// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black: #000;
@white: #FFF;

@oranje1: #004AAD;
@oranje2: #279B48;

@grey: #99999b;
@red: #c30e2e;
@darkred: #420510;

@banner-text: @black;

@color1: @oranje1;
@color2: @white;
@contrast1: @grey;
@contrast2: @white;
@textcolor: @grey;
@titlecolor: @grey;


// mix with white: tint()
// mix with black: shade()

// Templates
@leegheight: 100px; //400px;

// Breakpoints

/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";


// Typography
@contentfont: Arial, "Helvetica Neue", Helvetica, sans-serif;
@titlefont: @contentfont; //  "Comic Sans MS", cursive, sans-serif;