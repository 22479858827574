* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
}

body {
    font-family: @contentfont;
    color: @textcolor;
    .font-size(16);
    font-weight: 300;
    letter-spacing: 0.025em;
    line-height: 1.8;

    @media @phone, @xsmall {
        //.font-size(18);
    }
}

a {
    color: @color1;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }
/*
    &[href $='.pdf'],
    &[href $='.xls'],
    &[href $='.xlsx'],
    &[href $='.doc'],
    &[href $='.docx'],
    &[href $='.zip'],
    &[href $='.ppt'],
    &[href $='.pptx'] {
        &:not(.no-icon){
            &:before {
                .icon;
                display: inline-block;
                margin-right: 5px;
                font-size: 120%;
                vertical-align: text-bottom;
                content: "\e907";
            }
        }
    }
    &[href $='.pdf']:before { content: '\e905' !important;}
    &[href $='.xls']:before,
    &[href $='.xlsx']:before { content: '\e903' !important;}
    &[href $='.doc']:before,
    &[href $='.docx']:before { content: '\e904' !important;}
    &[href $='.zip']:before { content: '\e901' !important;}*/
}

.zerniq_wysiwyg {
    line-height: 1.8;
    color: @textcolor;

    h1, h2, h3, h4 {
        font-family: @titlefont;
    }
    h1, h2 {
        .font-size(30);
        color: @oranje1;
        font-weight: 300;
        text-transform: uppercase;
        text-align: left;
        margin: 0 0 15px;
        @media @phone, @xsmall {
            .font-size(22);
        }
        & + h1,
        & + h2 {
            margin-top: -0.5em;
        }
    }

    h2 {
        color: @color1;
    }

    h3, h4 {
        .font-size(18);
        color: @titlecolor;
        font-weight: normal;
        margin: 0 0 1em 0;

        @media @phone, @xsmall {
            .font-size(18);
        }
    }
    h4 {
        color: @color1;
    }

    h5, h6 {
        .kop;

        &.center-heading {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .block-title {
        //.kop;
        .font-size(30);
        font-weight: 300;
        text-align: center;
        color: @oranje1;
        font-family: @titlefont;
        text-transform: uppercase;
        margin: 10px 0 15px 0;
    }

    img {
        max-width: 100%;
        //height: auto !important;
    }

    p {
        margin: 0 0 1.3em 0;
        &.form-control-static {
            margin-bottom: 0;
        }
    }

    p + ul,
    p + ol {
        margin-top: -1.3em;
    }

    time {
        display: block;
        margin-bottom: 10px;
        font-weight: 500;
        .font-size(15);
    }

    ol {
        margin: 0;
        padding: 0;
        list-style-type: none;
        counter-reset: item;
        li {
            position: relative;
            margin: 0;
            padding-left: 50px;
            color: @oranje1;
            //min-height: 50px;
            counter-increment: item;
            //.font-size(20);
            padding-top: 2px;
            margin-bottom: 5px;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                content: counters(item,".") " ";
                width: 28px;
                height: 28px;
                line-height: 26px;
                .lh-border-radius(50%);
                background: @oranje1;
                color: @white;
                //.font-size(18);
                font-weight: 400;
                text-align: center;
                padding-top: 3px;
            }

        }
    }

    ul {
        margin: 0 0 1.3em 0;

        @media @phone {
            padding-left: 0;

            ul {
                padding-left: 15px !important;
            }
        }

        li {
            position: relative;

        }
    }


    strong {
        font-weight: 400;
    }

    .intro {
        .font-size(18);
        line-height: 1.67;
        font-weight: normal;
    }
/*
    blockquote {
        display: block;
        text-align: center;
        background: @color1;
        color: @white;
        font-weight: 400;
        margin: 0;
        padding: 25px 25px;
        border: none;
        .font-size(28);
        .lh-border-radius(10);
        line-height: 1.25;

        @media @phone, @xsmall {
            .font-size(22);
        }

        &:before {
            content: '\201C';
        }
        &:after {
            content: '\201D';
        }
    }*/

    .source {
        font-style: italic;
        font-size: 80%;
    }

    a {
        color: @color1;

        &:hover {
            color: @contrast1;
            text-decoration: underline;
        }
    }

    a.button {
        display: inline-block;
        padding: 2px 15px;
        color: @white;
        background: @color1;
        .lh-border-radius(3px);
        text-transform: uppercase;
        font-weight: 400;
        border: 2px solid @oranje1;

        &:hover {
            background: @white;
            color: @color1;
            text-decoration: none !important;
        }
        &.cart {
            &:after {
            .icon;
            content: '\f07a';
            .font-size(20);
                padding-left: 8px;

            }
        }
    }


}

.kop {
    //display: inline-block;
    display: table;
    text-align: left;
    color: @white !important;
    background: fade(@black, 80%);
    padding: 10px;
    font-family: @titlefont;
    .font-size(20) !important;
    font-weight: normal !important;
    text-decoration: none !important;
    margin-top: 0 !important;
    margin-bottom: 12px !important;
}

.header {
    background: @white;
    position: relative;
    z-index: 25;
    .clearfix();

    @media @large {
//        height: 175px;
    }
    @media @medium {
//        height: 153px;
    }
    @media @small {
//        height: 120px;
    }

    .container {
        height: 100%;
        //padding: 0;
        .clearfix;
    }



    .logo {
        position: relative;
        z-index: 20;
        .make-sm-column(3);
        .make-lg-column(4);
        margin-top: 30px;
        padding: 0;
        max-height: 140px;

        a {
            display: block;
            height: 100%;
        }
        img {
            max-width: 100%;
        }

        @media @small {
            margin-top: 25px;
        }

        @media @phone, @xsmall {
            margin: 10px 0 15px;
            width: 100%;
            //padding-right: 15px;
            text-align: center;


            img {
                max-width: 90%;
                max-height: 110px;
            }
        }
        .clearfix;
    }

    .logo2 {
        .make-sm-column(3);
        .make-sm-column-offset(5);
        margin-top: 28px;
        height:36px;

        h3 {
            display: none;
        }

        @media @phone, @xsmall {
            display: none;
        }
    }
}

.header-options {
    .font-size(22);

    @media @phone, @xsmall {
        display: none;
    }

    .make-sm-column(9);
    .make-lg-column(8);


    text-align: right;
    span {
        display: inline-block;
        margin-left: 20px;
    }

    .searchform {
        float: right;

    }

    @media @small {
        padding-top: 17px;
        .searchform {
            margin-top: 10px;
        }
    }

    @media @medium, @large {
        padding-top: 15px;
        margin-bottom: 15px;
        .searchform {
            margin-top: 10px;
        }
    }

}


/*
.header-options {
    .make-sm-column(9);

    padding-top: 10px;
    text-align: right;

    @media @small {
        height: 99px;
    }

    @media @medium, @large {
        height: 82px;
    }

    > div {
        line-height: 1;
    }


    .lang-select {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 8px;

        @media @small {
            margin-bottom: 10px;
        }

        ul {
            .no-bullets;
            height: 20px;

            li {
                display: inline-block;
                height: 20px;
            }
        }

        a {
            display: inline-block;
            width: 30px;
            height: 20px;
            margin: 0 5px;
            background-repeat: no-repeat;
            vertical-align: top;


            &.flag_nl {
                background-image: url('../images/flag_nl.png');
            }
            &.flag_en {
                background-image: url('../images/flag_uk.png');
            }
            &.flag_fr {
                background-image: url('../images/flag_fr.png');
            }
            &.flag_de {
                background-image: url('../images/flag_de.png');
            }

            &:last-child {
                margin-right: 0;
            }

            .lh-opacity(0.5);

            //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
            filter: gray;
            -webkit-filter: grayscale(100%);

            &.active, &:hover {
                .lh-opacity(1);
                //filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                -webkit-filter: grayscale(0%);
            }
        }
    }

    .login-link {
        display: inline-block;
        font-weight: bold;
        vertical-align: top;
        .font-size(12);
        color: @white;
        background: @grey;
        padding: 3px 10px 5px;
        line-height: 1;
        text-transform: uppercase;
        margin: 0 0 0 30px;
    }

    .header-menu {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 4px;

        @media @small {
            margin-bottom: 10px;
        }

        @media @phone, @xsmall {
            padding: 0;
        }

        ul {
            .no-bullets;
            li {
                display: inline-block;
                a {
                    display: inline-block;
                    .font-size(15);
                    color: @white;
                    text-transform: uppercase;
                    padding: 0 8px;

                    @media @phone, @xsmall {
                        .font-size(14);
                        padding: 0 5px;
                    }

                    &:hover {
                        color: @color1;
                        text-decoration: none;
                    }
                }

                &.active {
                    a {
                        color: @grey;
                    }
                }
            }
        }
    }

    .searchform {
        display: inline-block;
        vertical-align: middle;
        @media @phone, @xsmall {
            display: none !important;
        }

    }
}*/


.searchform {

    input {
        float: left;
        width: 200px;
        padding: 0 10px;
        border: 1px solid #b5baba;
        border-right: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        -webkit-appearance: none;
        .lh-border-radius(0);
    }
    button {
        float: left;
        width: 30px;
        text-align: center !important;
        border: 1px solid #b5baba;
        border-left: none;
        background: @white;
        height: 35px;
        vertical-align: middle;
        line-height: 1;
        -webkit-appearance: none;
        padding-top: 5px;

        &:after {
            .icon;
            color: fade(@grey, 40%);
            content: '\e900';
            .font-size(20);
        }
    }
}

/*

.searchresult {
    .make-md-column(10);
    padding: 0;
    margin: 0;

    .font-size(18) !important;
    font-weight: normal !important;

    h3 {
        .font-size(20) !important;
        margin: 0 !important;
    }
    p {

    }

    &:hover {
        color: @color1 !important;
        text-decoration: none !important;

        h3 {
            color: @color1 !important;
        }
    }
}
*/

.mainmenu {
    position: relative;
    z-index: 20;
    //background: fade(@white, 80%);

    background: fade(@oranje1,80%);

    @media @large {
        height: 77px;
        margin-bottom: -77px;
    }
    @media @medium {
        height: 67px;
        margin-bottom: -77px;
    }
    @media @small {
        height: 57px;
        margin-bottom: -57px;
    }

    @media @phone, @xsmall {
        .container {
            padding: 0;
        }
    }

    .navbar-header {
        background-color: @color1;
        margin: 0;

        .menu-header {
            @media @small, @medium, @large {
                display: none;
            }

            float: left;
            margin-left: 15px;
            max-width: 80%;

            a {
                display: inline-block;
                .font-size(20);
                line-height: 1;
                color: @color2;
                font-weight: bold;
                padding: 14px 0;
            }
        }

        .navbar-toggle {
            background-color: @white;
            margin-right: 15px;

            .icon-bar {
                background-color: @color1;
            }
        }
    }

    .menu {
        position: relative;
        z-index: 2;
        padding: 0;
        .lh-box-shadow(none);

        @media @small, @medium, @large {
            //height: 30px;
        }

        @media @phone, @xsmall {
            padding: 5px 15px;
            background: @oranje1;
            //margin: 0;
        }
        > ul {
            width: 100%;
            .no-bullets;
            .clearfix;
            text-align: left;
            @media @phone, @xsmall {
                padding-bottom: 15px;
            }

            > li {
                position: relative;
                display: inline-block;

                @media @phone, @xsmall {
                    padding: 0;
                    display: block;
                    width: 100%;
                }

                a {
                    display: block;
                    color: @white;
                    .font-size(18);
                    font-weight: normal;
                    text-transform: uppercase;

                    //.lh-transition(background 0.15s ease);

                    @media @phone, @xsmall {
                        padding: 5px 15px;
                        height: auto;
                        text-align: left;
                        .font-size(15);
                    }

                    @media @small, @medium, @large {
                        line-height: 1.1;
                        text-align: center;
                    }

                    @media @small {
                        .font-size(16);
                        padding: 20px 8px 20px;
                        max-height: 57px;
                    }

                    @media @medium {
                        padding: 25px 12px 25px;
                        max-height: 67px;
                    }

                    @media @large {
                        max-height: 77px;
                        padding: 30px 30px;
                    }

                }

                &:first-child {

                }

                &:hover {
                    > a {
                        color: @oranje1;
                        text-decoration: none;
                        background: fade(@white, 60%);
                    }

                    ul {
                        display: block;
                    }
                }



                @media @small, @medium, @large {
                    display: inline-block;

                    > a {
                        //letter-spacing: 0.25px;
                    }

                    &.active {
                        > a {
                            color: @oranje1;
                            background: @white;

                        }
                    }

                }

                ul {
                    .no-bullets;
                    //.lh-box-shadow(2px 2px 4px 0 rgba(0,0,0,0.2));
                    text-align: left;
                    //background-color: fade(@color1, 80%);

                    @media @small, @medium, @large {
                        display: none;

                        position: absolute;
                        left: 0;
                        top: 100%;
                        z-index: 15;
                        //background: @white;
                        min-width: 300px;
                        //border: 1px solid #bbb;
                        border-top: none;
                        //padding: 10px 0 0 0;

                        li {

                            a {
                                display: block;
                                text-align: left;
                                color: @grey;
                                //background: @white;
                                background: fade(@white, 90%);
                                .lh-transition(background 0.2s ease);
                                padding-top: 8px;
                                padding-bottom: 8px;
                                text-transform: none;

                                &:hover {
                                    color: @white;
                                    background-color: @color1;
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    @media @phone, @xsmall {
                        margin: 0 0 15px 0;

                        li {
                            a {
                                display: block;
                                .font-size(14);
                                line-height: 1.3;
                                text-transform: none;
                                padding: 5px 15px 5px 30px;
                                font-weight: 300 !important;
                            }
                        }
                    }

                }

                &:last-child {
                    ul {
                        left: auto;
                        right: 0;
                    }
                }

            }
        }
    }
}


.top-carrousel {

    position: relative;
    width: 100%;
    z-index: 0;


    @media @medium, @large {
        height: 490px;
    }
    @media @small {
        height: 400px;

    }
    @media @phone, @xsmall {
        height: 200px;
    }

    .bx-wrapper,
    .bx-viewport {
        display: block;
        height: 100%;
    }

    ul {
        .no-bullets;
        height: 100%;
        li {

            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            .container {
                position: relative;
                height: 100%;
                z-index: 2;
                padding: 0;
            }

            a {
                display: block;
                height: 100%;

                &:hover {
                    span:after {
                        background: fade(@white,80%);
                        color: @color1;
                    }
                }
            }

            .icon-image {
                position: absolute;
                right: 10%;
                bottom: 0;
                width: 40%;
                height: 30%;
                background-size: contain;
                background-position: right bottom;
                background-repeat: no-repeat;
            }

            span {
                display: block;
                //.make-sm-column(7);
                position: absolute;
                left: 0;
                bottom: 10px;
                //min-height: 130px;
                background: fade(@white, 75%);
                padding: 10px 80px 10px 20px;
                max-width: 50%;

                @media @phone, @xsmall {
                    width: 100%;
                    //min-height: 60px;
                    bottom: 15px;
                    max-width: 100%;
                    padding: 0 10px;
                    text-align: center;
                }

                h4 {
                    font-weight: normal;
                    color: @black;
                    text-transform: uppercase;

                    @media @small, @medium, @large {
                        .font-size(40);
                        font-weight: 300;
                        margin-bottom: 5px;
                    }

                    @media @phone, @xsmall {
                        .font-size(25);
                        font-weight: 300;
                    }
                }

                h6 {
                    color: @oranje1;
                    .font-size(25);
                    font-weight: normal;
                }

            }
        }
    }
    .bx-controls {
        //position: relative;
        z-index: 20;

        @media @phone, @xsmall {
            display: none;
        }

        .bx-controls-direction {
            a {
                background: fade(@white, 40%);
                text-indent: 0;
                width: 60px;
                height: 60px;
                margin-top: -25px;
                padding-top: 15px;
                text-align: center;
                text-decoration: none;
                &:before {
                    .icon;
                    .font-size(30);
                    color: @grey;

                }

                &.bx-prev {
                    left: 40px;

                    &:before {
                        content: '\e901';
                    }
                }
                &.bx-next {
                    right: 40px;

                    &:before {
                        content: '\e902';
                    }
                }

                &:hover {
                    background: fade(@white, 70%);
                    &:before {
                        color: @red;
                    }

                }
                @media @small, @phone, @xsmall {
                    background: fade(@black, 30%);
                    &.bx-prev {
                        left: 0;
                    }
                    &.bx-next {
                        right: 0;
                    }

                }
            }

        }
    }
}

.header-image {

    //.container;
    background-position: center;
    background-size: cover;

}


.header-image {
    position: relative;
    width: 100%;
    z-index: 0;


    @media @medium, @large {
        height: 300px;
    }
    @media @small {
        height: 240px;

    }
    @media @phone, @xsmall {
        height: 150px;
    }
}

/*.home-carrousel {
    position: relative;
    z-index: 10;
    @media @small, @medium, @large {
        height: 445px;
    }

    .container {
        @media @small, @medium, @large {
            padding-top: 118px;
        }

        @media @phone, @xsmall {
            padding-top: 40px;
            padding-bottom: 30px;
        }

        .carrousel-block {
            position: relative;
            .make-sm-column(10);
            .make-md-column(8);
            .make-lg-column(6);
            padding: 0;
            background: fade(@white, 80%);
            border-left: 10px solid @color1;
            overflow: hidden;

            @media @small, @medium, @large {
                height: 280px;
            }

            ul {
                .no-bullets;

                li {
                    padding: 50px 0 10px 30px;

                    a {
                        color: @textcolor;

                        & div:after {
                            display: inline-block;
                            content: 'lees meer';
                            float: left;
                            padding: 1px 15px;
                            background: @color1;
                            color: @white;
                            .font-size(14) !important;
                            text-transform: uppercase;
                            font-weight: normal !important;
                        }

                        &:hover {
                            text-decoration: none;
                            & div:after {
                                background: @color1;
                            }
                        }
                    }
                    h4 {
                        .font-size(24);
                        color: @grey;
                        font-weight: bold;
                        text-transform: uppercase;
                        line-height: 1.2;
                        margin: 0;
                    }
                    div {
                        .make-xs-column(8);
                        padding-left: 0;
                        margin-bottom: 10px;

                        p {
                            .font-size(16);
                            line-height: 1.4;
                        }
                        figure{
                            .make-xs-column(4);
                            padding-right: 0;
                            text-align: center;
                            img {
                                display: inline-block;
                                max-width: 100%;
                            }
                        }
                    }

                }
            }

            .controls {
                position: absolute;
                right: 10px;
                top: 0;
                //width: 60px;

                div {
                    display: inline-block;
                }

                .prev_btn a,
                .next_btn a,
                .toggle-play {
                    display: inline-block;
                    vertical-align: middle;
                    padding: 0 5px;
                    cursor: pointer;
                    color: @color1;

                    &:after {
                        .icon;
                        .font-size(14);
                    }

                    &:hover {
                        text-decoration: none;
                        color: @color1;
                    }
                }

                .prev_btn a {
                    &:after {
                        content: '\f04b';
                    }
                }
                .next_btn a {
                    &:after {
                        content: '\f04c';
                    }
                }
                .toggle-play {
                    color: fade(@grey, 50%);

                    &.active {
                        color: @color1;
                    }

                    &:after {
                        content: '\f04d';
                    }
                }
            }

        }
    }


}*/

.bg-image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;

    &:after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        height: 100%;
        width: 45%;
        .lh-background-image(linear-gradient(to right, fade(@red,0%) 0%, @red 100%););
    }

    @media @phone, @xsmall {
        left: auto;
        right: 0;

        &:after {
            right: auto;
            left: 0;
            .lh-background-image(linear-gradient(to right, @red 0%, fade(@red,0%) 100%););
        }
    }
}

.pageheader {
    position: relative;
    z-index: 10;

   hgroup {
       position: relative;
       z-index: 2;

        h1 {
            .font-size(30);
            color: @oranje1;
            font-weight: 300;
            font-family: @titlefont;
            text-transform: uppercase;
            text-align: left;
            margin: 0 0 15px;
        }
        h4 {
            .font-size(18);
            color: @titlecolor;
            font-weight: 300;
            margin: 0 0 15px;
        }


    }
}


.breadcrumbs {
    clear: both;
    position: relative;
    z-index: 3;
    background: @white;
    .font-size(16);
    color: @grey;
    font-family: @titlefont;
    text-transform: lowercase;

    @media @small, @medium, @large {
        margin-top: 80px;

        .header-image + &,
        .top-carrousel + & {
            margin-top: 0;
        }
    }

    @media @phone, @xsmall {
        .font-size(14);
    }


    .container {
        padding-top: 7px;
        padding-bottom: 7px;
        //padding-left: 0;
        //padding-right: 0;
        background: transparent;
    }
    ul, ol {
        .no-bullets;

        li {
            display: inline-block;

            a {
                color: @grey;
                &:hover {
                    color: @color1;
                    text-decoration: none;
                }
            }

            &:after {
                content: '|';
                display: inline-block;
                padding: 0 2px 0 5px;
            }

            &:last-child {
                color: @grey;
                a {
                    color: @grey;
                }
                &:after {
                    content: '';
                }
            }
        }
    }

    .sticky-wrapper + & {
        padding-top: 15px;
    }
}

.sidemenu {
    @media @phone, @xsmall {
        display: none;
    }

    ul {
        .no-bullets;
        border-top: 1px solid fade(@color1, 60%);
        li {
            border-bottom: 1px solid fade(@color1, 60%);
            a {
                position: relative;
                display: block;
                .font-size(18);
                font-weight: 300 !important;
                line-height: 1.1;
                color: @black !important;
                padding: 15px 8px 8px 8px !important;

                &:hover {
                    text-decoration: none !important;
                    background-color: fade(@color1, 20%);
                }
            }

            &.active {
                border-bottom: 0;
                > a {
                    background-color: fade(@color1, 83%);
                    color: @white !important;
                }
            }

            ul {
                border-top: 1px solid fade(@grey, 50%);
                li {
                    padding-left: 20px;
                    border-bottom: 1px solid fade(@grey, 50%);

                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }

    .page-home & {
        ul {
            li {
                a {

                }
            }
        }
    }
}


.content {
    clear: both;
    position: relative;
    z-index: 5;
    min-height: 200px;


    > section {
        padding-top: 20px;
        padding-bottom: 15px;

        /*&:first-child {
            @media @small, @medium, @large {
                padding-top: 50px;
            }

            @media @phone, @xsmall {
                padding-top: 30px;
            }
        }*/

        @media @phone, @xsmall {
            //margin: 0 15px;
        }

        .block {
            .clearfix;
            //min-height: 50px;
            &:extend(.zerniq_wysiwyg all);
        }
        &:not(.wide){
            > .block {
                .container;
                padding: 0;
            }
        }
        &.wide {
            > .block {
                .container-fluid;
                padding: 0;
            }
        }


        &.first-block {
            .col-left {
                .make-sm-column(4);
                .make-md-column(3);
            }
            .col-right {
                .make-sm-column(8);
                .make-md-column(9);
            }
        }

        &.bgcolor-1 {
            background: @oranje2;
            * {
                color: @black;
            }
            a, h1, h2, h3, h4 {
                color: @oranje1;
            }
        }

        &.bgcolor-2 {
            background: @color1;
            *, a, .block-title {
                color: @white;
            }
        }

        &.bgcolor-3 {
            position: relative;

            .block:first-child::before,
            .block:last-child::after {
                content: '';
                //position: absolute
                //display: block;
                margin: 5px auto;
                height: 0;
                width: 50%;
                min-width: 200px;
                border-top: 5px solid @color1;
            }
        }

        // Homepage exceptions

        /*&:first-child {
            .page-home & {
                padding-top: 0;
                background: @grey;
                .block * {
                    color: @white !important;
                }
            }
        }*/

        &:not([class^='content-1']) .block-title {
            .make-sm-column(12);
        }
    }


    //.banners-120, .banners-121, .banners-122, .banners-123 {

    //}


    .image-block {
        min-height: 100px !important;
        background-position: center;
        background-size: cover;
    }
}

/*.addthis {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;

}*/
/*
.contact-131 {
    .block {
        background: @grey;

        //@media @small, @medium, @large {
            .lh-border-radius(10px);
            overflow: hidden;
        //}
    }



    .col1 {
        padding: 0 !important;

    }

    .col2 {
        padding-top: 15px;
        * {
            color: @white;
        }
    }
}*/


.newslink {

   /* figure {
        .make-sm-column(4);
        height: 170px;
        background-position: center;
        background-size: cover;
    }*/
    span {
        //.make-sm-column(8);

        h3 {
            height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .font-size(18) !important;
            margin: 0 0 10px 0 !important;
            text-transform: none !important;
        }
        time {
            .font-size(13) !important;
            color: #999999;
            margin-bottom: 3px !important;
        }
        p {
            .font-size(15) !important;
            font-weight: normal !important;
            margin: 0 !important;
            max-height: 81px;
            overflow: hidden;
        }

        &:after {
            display: block;
            text-align: center;
            .icon;
            content: '\e901';
            color: @color1;
            margin-top: 10px;
            .font-size(50) !important;
            font-weight: normal !important;
        }
    }

    &:hover {
        text-decoration: none !important;

        span:after {
            color: @grey;
        }
    }
}
/*.banner,
.newslink {
    @media @large {
        height: 520px;
    }
    @media @medium {
        height: 200px;

    }

    @media @small {
        height: 260px;
    }

    @media @phone, @xsmall {
        height: 220px;
        margin: 0 0 @grid-gutter-width 0;
    }
}*/
/*
.project-page {
    .project-image {
        .make-sm-column(8);
        padding-bottom: 20px;
    }
    .project-content {
        .make-sm-column(4);
        padding-bottom: 20px;
    }


}

.project-related {
    background-color: #e8e8e8;
    .project {
        .make-sm-column(3);
    }
}

.project-nav {
    margin: 20px 0 0 0;

    padding-top: 8px;
    .clearfix;
    a {
        display: block;
        padding: 15px;
        border: 1px solid fade(@grey, 70%);
        .lh-border-radius(3px);
        text-transform: uppercase;
        color: fade(@grey, 70%);

        &:before, &:after {
            display: inline-block;
            margin: 0 3px 3px 3px;
            .font-size(22);
            vertical-align: middle;
        }
        &:hover {
            text-decoration: none;
            background: fade(@grey, 10%)
        }

        @media @phone, @xsmall {
            margin-bottom: 8px;
        }
    }
    .prev {
        .make-sm-column(5);
        a:before {
            .icon;
            content: '\e5c4';
        }
    }
    .next {
        .make-sm-column(5);
        a {
            text-align: right;
            &:after {
                .icon;
                content: '\e5c8';
            }
        }
    }
    .overview {
        .make-sm-column(2);
        a {
            text-align: center;
            &:after {
                .icon;
                .font-size(30);
                content: '\e903';
            }
        }
    }
}*/

/*
.team-member {
    .make-sm-column(6);
    margin-bottom: 30px;
    //.make-row();
    padding: 0;

    &:nth-child(odd) {
        clear: both;
    }

    figure {
        position: relative;
        .make-sm-column(4);
        text-align: left;

        @media @phone, @xsmall {
            margin-bottom: 15px;
            img {
                width: 100%;
                max-width: 260px !important;
            }
        }

        .linkedin {
            display: block;
            position: absolute;
            bottom: 10px;
            left: 25px;
            width: 32px;
            height: 32px;
            background: @color1;
            text-align: center;
            padding-top: 3px;

            &:before {
                .icon;
                color: @white;
                content: '\f0e1';
                .font-size(24);
            }
            &:hover {
                text-decoration: none !important;
                background: @darkred;
            }
        }
    }
    .info {
        .make-sm-column(8);
        font-weight: normal;
        line-height: 1.3;
        .font-size(16);

        h3 {
            .font-size(20) !important;
            font-weight: normal !important;
            color: @color1 !important;
            margin: 0 !important;
        }

    }
}*/
/*
.quote-item {
    .font-size(16);
    .make-sm-column(10);
    .make-sm-column-offset(1);
    text-align: center;
    padding-bottom: 15px;
    padding-top: 10px;

    q {
        display: block;
        margin-bottom: 15px;
        .font-size(30);
        line-height: 1.2;
        font-family: @scriptfont;
        font-style: italic;

        &:before,
        &:after {
            content: '';
        }
    }

    .name {
        //font-weight: bold;
    }
}*/

.product-group-page {
    .block {
        padding: 0 15px !important;
    }
    .group-info {
        .make-row();
        .make-sm-column(8);
        .clearfix;
    }
    .group-products {
        .make-row();
        clear:both;
    }
}

.product-page {
    .block {
        padding: 0 15px !important;

        figure {
            .make-sm-column(6);
            padding-left: 0;

            .product-carrousel {
                margin-bottom: 10px;
                ul {
                    .no-bullets;
                    li {
                        display: block;
                        img {
                            width: 100%;
                        }
                    }
                }
            }

            span {
                display: block;
                text-align: center;

                &:before {
                    .icon;
                    display: inline-block;
                    content: '\e903';
                    margin-right: 4px;
                    color: @oranje1;
                }
            }
        }
        .product-info {
            margin-bottom: 30px;
            .make-sm-column(6);

            .specs {
                margin: 0 0 30px;
                table {
                    tr {
                        td, th {
                            padding: 0 15px 0 0;
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .formbutton {
                margin: 30px 0 0 0;
            }
        }
        .seotekst {
            .make-sm-column(8);
            margin-top: 30px;
            padding-left: 0;
            //.make-sm-column-offset(2);
        }
    }
}

.group-products {
    .clearfix;
    .make-row;

    > div {
        .make-sm-column(4);
        .make-md-column(3);

        @media @small {
            &:nth-child(3n+1){
                clear: both;
            }
        }
        @media @medium, @large {
            &:nth-child(4n+1){
                clear: both;
            }
        }
    }

    .product-item {
        &:extend(.product-banner all);
    }
}

.related-products {
    //.clearfix;
    //.make-row;

    h3 {
        color: @oranje1 !important;
        .font-size(20) !important;
        margin: 20px 0 40px 0!important;
        text-transform: uppercase;
        text-align: center;
        font-weight: 300 !important;

        strong {
            font-weight: 400 !important;
        }
    }
    nav {
        > div {
            .make-sm-column(4);
            .make-md-column(3);

            @media @medium, @large {
                &:nth-child(4n+1){
                    clear: both;
                }
            }
        }

        .product-item {
            &:extend(.product-banner all);
        }
    }
}

.product-item {
    &:extend(.product-banner all);
}

.product-banner {
    //.make-sm-column(3);
    display: block;
    margin-bottom: 40px;
    position: relative;
    z-index: 15;

    figure {
        border: 1px solid tint(@black, 30%);
        position: relative;
        background-position: center;
        background-size: cover;
        background-color: fade(@oranje1, 8%);

        overflow: hidden;

        @media @large {
            height: 300px;
        }
        @media @medium {
            height: 212px;
        }
        @media @small {
            height: 220px;
        }
        @media @phone,@xsmall {
            height: 300px;
        }

        &:after {
            position: absolute;
            bottom: 20px;
            left: 50%;
            margin-left: -10px;
            content: '\f138';
            .icon;
            .font-size(25);
            color: @oranje2;
            text-shadow: 0 0 5px fade(@black,80%);
            .lh-opacity(1);
            .lh-transition(all 0.3s ease);
        }

        .specs {
            //display: none;
            position: absolute;
            bottom: -100%;
            left: 0;
            width: 100%;
            background: fade(@white,80%);
            .lh-opacity(0);
            .lh-transition(all 0.3s ease);
            padding: 10px 0 50px 0;

            table {
                width: 100%;
                tr {
                    th, td {
                        width: 50%;
                        padding:0 5px;
                    }

                    th {
                        text-align: right;
                    }
                    td {
                        text-align: left;

                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }

            }
        }
    }

    h4 {
        text-align: center;
        margin: 10px 0 0 0 !important;
        .font-size(16) !important;
    }



    &:hover {
        text-decoration: none !important;

        figure {
            &:after {
                //.lh-opacity(0);
                color: @oranje1;
                text-shadow: 0 0 7px @white;
            }

            .specs {
                .lh-opacity(1);
                bottom: 0;
            }
        }
    }
}

.news-single-item {
    &:extend(.content-10 all);
    .fluid-width-video-wrapper {
        margin-bottom: 10px;
    }

    nav {
        .make-sm-column(3);

        @media @phone, @xsmall {
            display: none;
        }

        ul {
            .no-bullets;
            li {
                margin-bottom: 10px;
                a {
                    .font-size(16);
                    line-height: 1.1 !important;
                    color: @grey !important;
                    &:hover {
                        color: @color1 !important;
                        text-decoration: none !important;
                    }
                }
            }
        }
    }
    article {
        .make-sm-column(9);
        @media @phone, @xsmall {
            padding: 0;
        }
    }
}

/*.contactfooter {
    border-top: 130px solid @white;
    background: @grey;
    color: @color1;
    text-align: center;




    p {
        .font-size(22);
        margin: 0;

        strong {
            font-weight: 400;
            .font-size(20);
            color: @color1;
            a {
                color: @color1;
            }
        }
    }

    .container {
        position: relative;

        @media @small,@medium  {
            padding: 20px 20px 20px 230px;
        }

        @media @large {
            padding: 20px 240px;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 20px;
            background: url('../images/bas-peijnenburg.png') no-repeat;
            width: 220px;
            height:221px;
        }
    }

    @media @phone, @xsmall {
        border-top: none;

        .container {
            padding: 15px 15px 150px 15px;

            &:after {
                background-size: contain;
                background-position: left bottom;
                height: 140px;
                left: 50%;
                margin-left: -70px;
            }
        }
    }

}*/

.deurmat {
    position: relative;
    z-index: 2;
    background: #99999b;
    color: @black;
    .font-size(16);
    font-weight: 400;
    line-height: 1.87;

    .container {
        padding-top: 30px;
        padding-bottom: 15px;
    }

    a {
        color: @black;
        &:hover {
            text-decoration: underline;
        }
    }

    .column {
        .make-sm-column(4);
        .make-md-column(2);

        &:first-child {
            .make-md-column(3);
        }
        &:last-child {
            .make-md-column(3);
        }

        @media @small {
            &:nth-child(3){
                clear: both;
            }
        }
        @media @phone, @xsmall {
            margin-bottom: 15px;
        }
    }
    p {
        margin: 0 0 20px 0;
    }

    p + ul {
        margin-top: -20px;
    }

    ul {
        .no-bullets;
        //margin: 0 0 20px 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 8px;
            /*&:before {
                position: absolute;
                left: 0;
                top: 0;
                content: "-\0020";
            }*/
        }
    }

    .metaalunie {
        background: url('../images/metaalunie.png') no-repeat center top;
        background-size: contain;
        max-width: 167px;
        height:46px;
        margin: 0 auto;
    }

}
.footer {
    position: relative;
    z-index: 2;
    padding: 8px 0;
    background: @oranje1;
    .clearfix;

    .column {
        .make-sm-column(6);
        color: fade(@white, 60%);
        .font-size(14);
        font-weight: 400;
    }

    .copyright {
        //text-align: center;
    }
    .credits {
        text-align: right;
        a {
            color: fade(@white, 60%);
            &:hover {
                text-decoration: none;
                color: @white;
            }
        }
    }

}

.modal-body {
    img {
        max-width: 100%;
        height: auto;
    }
}
.modal-header {
    border-bottom: none;
}

#whatsapp {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;

    a:after {
        .icon;
        text-align: center;
        content: "\f232";
        display: inline-block;
        .lh-border-radius(50%);
        background-color: #25d366;
        color: @white;

        @media @small, @medium, @large {
            width: 60px;
            height: 60px;
            line-height: 60px;
            .font-size(40);
        }
        @media @phone, @xsmall {
            width: 32px;
            height: 32px;
            line-height: 32px;
            .font-size(24);
            margin-left: 0.5rem;
        }
    }
    a {
        display: block;
        .lh-border-radius(50%);
        color: @text-color;
        text-decoration: none !important;
        text-align: center;
        @media @small, @medium, @large {
            b {
                .font-size(24);
            }
            span {
                display: inline-block;
            }

        }
        @media @phone, @xsmall {
            br {
                display: none;
            }
            span {
                display: none;
            }
            line-height: 32px;
        }
    }
}