section.banners-123 {

    .banner-container {
        //.make-row();
        //.make-sm-column(10);
        //.make-sm-column-offset(1);

        /*div {
            a {
                &:extend(.banner all);

                span {
                    text-align: left;
                    &:after {
                        .icon;
                        content: 'lees meer\0020\e900';
                        font-weight: normal;
                    }
                }
            }

            &:nth-child(odd){
                h3 {
                    color: @oranje1;
                }
                p, span:after {
                    color: @grey;
                }
            }

            &:nth-child(even){

                figure {
                    background-position: center bottom;
                }
                h3 {
                    color: @grey;
                }
                p, span:after {
                    color: @oranje1;
                }
            }
        }*/

    }
    .col1 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }
    .col2 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            margin-bottom: @grid-gutter-width;
        }
    }

    .col3 {
        .make-sm-column(6);
        .make-md-column(3);
        @media @small {
            clear: both;
        }
    }
    .col4 {
        .make-sm-column(6);
        .make-md-column(3);
    }


}

.banners-123 + .banners-123 {
    @media @phone, @xsmall {
        margin-top: -50px;
    }
}
